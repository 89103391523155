import {
  requiredRule,
  createMaxRule,
  typeNumberRule,
  createMinRule,
  createDecimalRule,
  typeNumberOrEmptyRule,
  createMaxStringRule,
  getTypeIntRule
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import Decimal from 'decimal.js';

import sumBy from 'lodash/sumBy';
import isString from 'lodash/isString';

import parseISO from 'date-fns/parseISO';

import ProductItemEntity, {
  ValidatorRules as ProductItemEntityValidatorRules
} from "@/entities/product-item-entity";
import ProductDisplaySetting from "@/entities/product-display-setting";
import AttachmentEntity from "@/entities/attachment-entity";
import AllergyEntity from "@/entities/allergy-entity";
import {IAmountItem} from "@/entities/ingredient-item-entity";
import CarryOver from "@/entities/carryover-entity";
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import IngredientEntity from "@/entities/ingredient-entity";
import UserEntity from "@/entities/user-entity";
import {NutritionValue} from "@/entities/nutrition-value";
import ProductCommonNameEntity from "@/entities/product-common-name-entity";
import {multiplyWithTax} from "@/services/tax";
import ProductLabelEntity from "@/entities/product-label-entity";
import ProductLabelLayoutEntity, {
  ValidatorRules as LabelLayoutValidatorRules
} from "@/entities/product-label-layout-entity";
import ProductNutritionOverrideEntity from "@/entities/product-nutrition-override-entity";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import {IProductMixin} from "@/entities/concerns/i-product-mixin";
import {IngredientWithAmount} from "@/entities/concerns/ingredient-with-amount";
import {IngredientWithAmountCollection} from "@/entities/concerns/ingredient-with-amount-collection";
import IngredientItemGmoEntity, {GmoEntity} from "@/entities/ingredient-item-gmo-entity";
import {IHasParent, IRecipeComponent, IRecipeComponentItem} from "@/entities/interfaces/i-recipe-component";
import {LabelEntityValidatorRules} from "@/entities/interfaces/i-label-entity";
import {ProductLabelIdentificationMarkValidatorRules} from "@/entities/product-label-identification-mark-entity";
import round from "lodash/round";

export default class ProductEntity implements IRecipeComponent {
  public static readonly KEY_PREFIX = "prod-";

  public id!:number;
  public companyOrd!:number;
  public name!:string;
  public displayName!:string | null;
  public isAssort:boolean = false;
  public intraCode:string|null = null;
  public intraCategoryId:number|null = null;
  public price:number|null = null;

  public customCommonName:string|null = null;

  public productCommonNameId:number|null = null;
  public productCommonName:ProductCommonNameEntity|null = null;
  public prop_productCommonNameModel:ProductCommonNameEntity|string|null = null;

  public productItems:ProductItemEntity[] = [];
  public parentItem:IRecipeComponentItem|null = null;

  public productDisplaySetting!:ProductDisplaySetting;

  public yieldPercent:number|null = null;
  public isYieldAffectToNutrition:boolean = false;
  public isYieldAsConcentrationReduction:boolean = false; // 濃縮・水戻しとして扱う

  public locked:boolean = false;
  public note:string = "";
  public appealText!:string | null;
  public createdAt!:Date;
  public updatedAt!:Date;

  public createdByUser!:UserEntity;
  public updatedByUser!:UserEntity;

  public attachments:AttachmentEntity[] = [];

  public isNutritionOverride:boolean = false;

  public showLabelSetting:boolean = false;
  public productLabel!:ProductLabelEntity;
  public productLabelLayout!:ProductLabelLayoutEntity;

  public productNutritionOverride!:ProductNutritionOverrideEntity;

  public tags: string[] = [];

  public departments: CompanyDepartmentEntity[] = [];
  public departmentIds: number[] = [];

  // View用
  public isYieldEnabled!:boolean;

  constructor(init:Partial<ProductEntity> = {}, parentItem:IRecipeComponentItem|null = null) {
    ObjectUtils.assignLiteralFields(this, init);

    this.parentItem = parentItem;

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }
    if (init.updatedAt) {
      this.updatedAt = parseISO((init as any).updatedAt);
    }
    if (init.createdByUser) {
      this.createdByUser = new UserEntity(init.createdByUser);
    }
    if (init.updatedByUser) {
      this.updatedByUser = new UserEntity(init.updatedByUser);
    }

    if (init.productItems) {
      this.productItems = init.productItems.map(i => new ProductItemEntity(i, this));
    }

    if (init.productCommonName) {
      this.productCommonName = new ProductCommonNameEntity(init.productCommonName);
    }

    this.tags = init.tags || [];

    this.departments = init.departments || [];
    this.departmentIds = this.departments.map(d => d.id);

    this.prop_productCommonNameModel = this.productCommonName || (init.customCommonName || '');

    this.productDisplaySetting = new ProductDisplaySetting(init.productDisplaySetting);


    this.isYieldEnabled = !!init.yieldPercent;

    this.productLabel = new ProductLabelEntity(init.productLabel || {});
    this.productLabelLayout = new ProductLabelLayoutEntity(init.productLabelLayout || {});
    this.productNutritionOverride = new ProductNutritionOverrideEntity(init.productNutritionOverride || {});
  }

  public get priceModel() { return this.price; }
  public set priceModel(val) { this.price = (val || null); }

  public getPriceWithTax(isTaxEight: boolean = false, roundUp: boolean = this.productLabelLayout.roundupTax):number|null {
    if(this.price === null) return null;
    const tax = isTaxEight ? 0.08 : 0.1;

    return roundUp ?
      Math.ceil(multiplyWithTax(this.price, tax)) :
      Math.floor(multiplyWithTax(this.price, tax));
  }

  public get key() { return ProductEntity.KEY_PREFIX + this.id; }
  public get isProduct() { return true; }

  public getDisplayName(): string {
    return this.displayName || this.name;
  }
  public get commonName(): string {
    return this.productCommonName ? this.productCommonName.name : this.customCommonName || "";
  }

  public getNameWithDisplayName(): string {
    return this.displayName ?
      this.name + "（" + this.displayName + "）" :
      this.name;
  }

  public get productCommonNameModel(): ProductCommonNameEntity|string|null {
    return this.prop_productCommonNameModel!;
  }
  public set productCommonNameModel(value:ProductCommonNameEntity|string|null) {
    this.prop_productCommonNameModel = value;
    if(isString(value)) {
      this.productCommonNameId = null;
      this.productCommonName = null;
      this.customCommonName = value as string;
    } else if(value === null) {
      this.productCommonNameId = null;
      this.productCommonName = null;
      this.customCommonName = null;
    } else {
      this.productCommonNameId = (value as ProductCommonNameEntity).id;
      this.productCommonName = value;
      this.customCommonName = null;
    }
  }

  public get shouldSeparateNutrition(): boolean {
    return this.isAssort && !this.productLabel.assortNutritionGrouped;
  }

  public getAmountSumGram(): number {
    return sumBy(this.productItems, i => Number(i.amountComputed));
  }

  public get shouldYieldAsConcentrationReduction(): boolean {
    return (new IProductMixin(this)).shouldYieldAsConcentrationReduction;
  }
  public get yieldRate() {
    return (new IProductMixin(this)).yieldRate;
  }
  public get concentrationRatio(): number {
    return (new IProductMixin(this)).getConcentrationRatio();
  }
  public getRateForIngredientOrder(calcWithReducedWeight: boolean): number {
    return (new IProductMixin(this)).getRateForIngredientOrder(calcWithReducedWeight);
  }

  public get costPerKgComputed():number {
    return sumBy(this.productItems, (i:ProductItemEntity) => {
      return (i.costPerKgComputed || 0) * i.amountRatioInTheDirectParent;
    }) * (1 / this.yieldRate) ;
  }
  public get costSumComputed():number {
    return sumBy(this.productItems, (i:ProductItemEntity) => i.costSumComputed || 0);
  }
  public get costRatioComputed(): number|null {
    if(!this.price) return null;
    return this.costSumComputed / this.price;
  }

  public getIngredientWithAmountRecursiveForNutritionRow(forNutrition: boolean, parentAmountRatio: number = 1, parentConcentrationRatio: number = 1):IngredientWithAmount[]
  {
    const concentrationRatio = parentConcentrationRatio * this.concentrationRatio;
    return this.productItems.flatMap(
      (pi: ProductItemEntity) => pi.getIngredientWithAmountRecursiveForNutritionRow(forNutrition, parentAmountRatio, concentrationRatio)
    );
  }
  public getTotalConcentrationRate(forNutrition): number {
    const ings = this.getIngredientWithAmountRecursiveForNutritionRow(forNutrition);
    const ingCollection = new IngredientWithAmountCollection(ings);
    return ingCollection.getTotalConcentratedAmountRatio() / this.concentrationRatio;
  }
  public getNutritionPer100g(property:string):NutritionValue {
    if (!this.getAmountSumGram()) return NutritionValue.None;

    const ings = this.getIngredientWithAmountRecursiveForNutritionRow(true);
    const vals = ings.map(i => {
      const val = i.ingredient.getNutritionValue(property);
      return NutritionValue.calc([val], () => val.number! * i.getConcentratedAmountRatio(), property, 100 );
    });
    return NutritionValue.calc(vals, () => {
      return sumBy(vals, v => v.number!);
    }, property, 100);
  }
  public getNutritionPerLabelAmount(property:string): NutritionValue {
    if (this.isNutritionOverride) {
      return this.productNutritionOverride.getNutrition(property);
    } else if (this.shouldSeparateNutrition) {
      return this.getNutritionPer100g(property);
    } else {
      const valuePer100g = this.getNutritionPer100g(property);
      return this.productLabel.getNutritionPerAmount(valuePer100g, property);
    }
  }

  public getAllChildren(): ProductEntity[] {
    return this.productItems
      .filter(pi => !!pi.childProduct)
      .map(pi => pi.childProduct!);
  }
  public getAllChildrenRecursive(): IRecipeComponent[] {
    return this.productItems
      .filter(pi => !!pi.item)
      .flatMap(pi => [pi.item!].concat(pi.item!.getAllChildrenRecursive()));
  }
  public getAllChildIngredientsRecursive(): IngredientEntity[] {
    return this.productItems.filter(pi => !!pi.item).flatMap((pi: ProductItemEntity) => {
      if (pi.childProduct) {
        return pi.childProduct.getAllChildIngredientsRecursive();
      } else {
        if (pi.ingredient!.isPreproduct) {
          return pi.ingredient!.getAllChildIngredientsRecursive();
        } else {
          return [pi.ingredient!].concat(...pi.ingredient!.getAllChildIngredientsRecursive());
        }
      }
    });
  }

  public get items() { return this.productItems; }

  public getParentsRecursive(): Array<IHasParent> {
    if(!this.parentItem) return [];
    return [this.parentItem, ...this.parentItem.getParentsRecursive()];
  }

  public findAmountRatioForIngredientOrderInTheRoot(needle:IAmountItem, calcWithReducedWeight: boolean, parentRatio = 1):number|null {
    for(let i = 0; i < this.productItems.length; i++) {
      const pi = this.productItems[i];
      if (!pi.item) continue;

      const ratio = (pi.amountGram || 0) / this.getAmountSumGram() * parentRatio;
      const res = pi.item.findAmountRatioForIngredientOrderInTheRoot(
        needle,
        calcWithReducedWeight,
        ratio * this.getRateForIngredientOrder(calcWithReducedWeight),
      );
      if (res) return res;
    }

    return null;
  }

  public get madeInAreas() {
    return this.productItems.map(pi => pi.madeInAreas).flat();
  }
  public getAllAllergens(): AllergyEntity[] {
    return this.productItems.flatMap(pi => pi.getAllAllergens()).sort((a,b) => a.order - b.order);
  }
  public getAllGmos(): IngredientItemGmoEntity [] {
    return this.productItems.flatMap(pi => pi.getAllGmos());
  }
  public getAllIngredientAdditivesRecursive(): IngredientAdditiveEntity[] {
    return this.getAllChildIngredientsRecursive().flatMap(ing => ing.getAllIngredientAdditivesRecursive() );
  }
  public getAllCarryoversRecursive(): CarryOver[] {
    return this.items.flatMap(pi => pi.getChildCarryoversRecursive());
  }

  public get isProductMix(): boolean {
    if (this.isAssort) return false;
    return this.productItems.some(pi => !!pi.childProduct);
  }
}

export const ValidatorRules = {
  name: [requiredRule, createMaxRule(128, 'char')],
  displayName: [createMaxRule(128, 'char')],
  intraCode: [createMaxStringRule()],
  price: [getTypeIntRule()],
  amountPerUnit: [typeNumberRule, createMaxRule(999999), createMinRule(0.001)],
  yieldPercent: [requiredRule, typeNumberOrEmptyRule, createMaxRule(9999), createMinRule(0.0001), createDecimalRule(8,4)],
  items: ProductItemEntityValidatorRules,
  productLabel: {
    ...LabelEntityValidatorRules,
    identificationMarks: ProductLabelIdentificationMarkValidatorRules,
  },
  productLabelLayout: LabelLayoutValidatorRules,
};
