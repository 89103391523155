import ObjectUtils from "@/utils/object-utils";
import IngredientEntity, {IngredientUnitEntity} from "@/entities/ingredient-entity";

import ProductEntity from "@/entities/product-entity";
import {createDecimalRule, createMinRule, requiredRule, typeNumberRule} from "@/utils/validation-rules";
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import CarryOverHandler from "@/entities/concerns/carryover-handler";
import AllergyEntity from "@/entities/allergy-entity";
import RowKey from "@/entities/concerns/rowkey";
import IngredientItemEntity from "@/entities/ingredient-item-entity";
import CarryOver from "@/entities/carryover-entity";
import { IngredientWithAmount } from "./concerns/ingredient-with-amount";
import IngredientItemGmoEntity from "@/entities/ingredient-item-gmo-entity";
import {
  getAmountGram,
  IHasParent,
  IRecipeComponent,
  IRecipeComponentItem,
  RecipeComponentItemUnitType,
  RecipeComponentItemUnitTypeDict,
  RecipeComponentItemUnitTypeDictReverse,
  RecipeComponentItemUnitTypeLabel
} from "@/entities/interfaces/i-recipe-component";
import {init} from "@sentry/browser";
import {concat} from "lodash";


export default class ProductItemEntity implements IRecipeComponentItem {
  public id!:number;
  public amountGram:number|null = null;
  public isCompositeSplitted:boolean|null = null;

  public unitType: RecipeComponentItemUnitType = RecipeComponentItemUnitType.GRAM;
  public ingredientUnitId: number | null = null;
  public ingredientUnit!: IngredientUnitEntity | null;

  public productId?:number;
  public product!:ProductEntity;

  public ingredient:IngredientEntity|null = null;
  public childProduct:ProductEntity|null = null;

  public ingredientId:number|undefined;
  public childProductId:number|undefined;

  public carryoverHandler!:CarryOverHandler;
  public carryovers:CarryOver[] = [];
  public rowKey!:number;

  constructor(init:Partial<ProductItemEntity> = {}, parent:ProductEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    this.product = parent;

    if (init.ingredient) {
      this.item = new IngredientEntity(init.ingredient, this);
    } else if (init.childProduct || (init as any).childProductForList) {
      this.item = new ProductEntity(init.childProduct || (init as any).childProductForList, this);
    }

    if(init.carryovers) {
      this.carryovers = init.carryovers.map(c => new CarryOver(c));
    }

    this.ingredientUnit = init.ingredientUnit ? new IngredientUnitEntity(init.ingredientUnit) : null;

    this.rowKey = RowKey.getRowKey();
    this.carryoverHandler = new CarryOverHandler(this.carryovers || [], this);
  }

  public get isChildProduct() {
    return !!this.childProduct;
  }
  public get childId():number|undefined {
    return this.childProductId || this.ingredientId;
  }
  public get parent():IRecipeComponent { return this.product; }

  public get key():string|null {
    return this.item ? this.item!.key : null;
  }
  public propItem:IRecipeComponent|null = null;
  public get item() :IRecipeComponent|null {
    return this.propItem;
  }
  public set item(val :IRecipeComponent|null) {
    this.childProduct = null;
    this.childProductId = undefined;
    this.ingredient = null;
    this.ingredientId = undefined;

    if (val instanceof IngredientEntity) {
      this.ingredientId = val.id;
      this.ingredient = val;
    } else if (val instanceof ProductEntity) {
      this.childProductId = val.id;
      this.childProduct = val;
    }
    this.propItem = val;

    if (this.carryoverHandler) {
      this.carryoverHandler.init();
    }
  }

  public get amount():number|null { return this.amountGram; }
  public set amount(val:number|null) { this.amountGram = val!; }

  public get amountComputed():number {
    return getAmountGram(this.unitType, Number(this.amountGram), this.ingredientUnit);
  }

  public get amountRatioInTheDirectParent() {
    return this.getAmountRatioInTheDirectParent(this.parent.getAmountSumGram());
  }
  public getAmountRatioInTheDirectParent(parentSumGram:number) {
    return this.amountComputed / parentSumGram;
  }

  public get madeInAreas() {
    if (!this.item) return [];

    return this.childProduct ?
      this.childProduct.madeInAreas : this.ingredient!.ingredientMadeInAreas;
  }

  public getChildIngredient(): IngredientEntity|null {
    return this.ingredient;
  }
  public getChildIngredientItemsRecursive(): IngredientItemEntity[] {
    if (this.childProduct) {
      return this.childProduct.productItems.flatMap(pi => pi.getChildIngredientItemsRecursive());
    } else if (this.ingredient) {
      return this.ingredient.ingredientItems.flatMap(ii => ii.getChildIngredientItemsRecursive());
    } else {
      return [];
    }
  }
  public getIngredientWithAmountRecursiveForNutritionRow(forNutrition: boolean, parentRatio: number = 1, parentConcentrationRatio: number = 1): IngredientWithAmount[]
  {
    const amountRatio = parentRatio * this.amountRatioInTheDirectParent;
    if (this.childProduct) {
      return this.childProduct.getIngredientWithAmountRecursiveForNutritionRow(forNutrition, amountRatio, parentConcentrationRatio);
    } else if (this.ingredient) {
      return this.ingredient.getIngredientWithAmountRecursiveForNutritionRow(forNutrition, amountRatio, parentConcentrationRatio);
    } else {
      return [];
    }
  }

  public getAdditivesRecursive():IngredientAdditiveEntity[] {
    if (this.childProduct) {
      return this.childProduct.productItems.flatMap(pi => pi.getAdditivesRecursive());
    } else if (this.ingredient) {
      return this.ingredient.ingredientAdditives.concat(
        ...this.ingredient.ingredientItems.flatMap(ii => ii.getAdditivesRecursive())
      ).flat();
    } else {
      return [];
    }
  }

  public getParentsRecursive(): IHasParent[] {
    if (!this.parent) return [];
    return [this.parent, ...this.parent.getParentsRecursive()];
  }

  public getChildCarryoversRecursive(includeSelf = true):CarryOver[] {
    if (!this.item) return [];

    const concat = includeSelf ? this.carryovers : [];
    return this.item!.items.flatMap(pi => {
      return pi.carryovers.concat(...pi.getChildCarryoversRecursive());
    }).concat(...concat);
  }

  public get costPerKgComputed():number|null {
    if (!this.item) return null;
    return this.item!.costPerKgComputed;
  }
  public get costSumComputed():number|null {
    if (this.costPerKgComputed === null || this.amountGram === null) return null;
    const amountKg = this.amountComputed / 1000;
    return this.costPerKgComputed * amountKg;
  }

  public getAllAllergens(): AllergyEntity[] {
    if (!this.item) return [];
    return this.item!.getAllAllergens();
  }
  public getAllGmos(): IngredientItemGmoEntity[] {
    if (!this.item) return [];
    return this.item!.getAllGmos();
  }

  public set unitTypeModel(val: RecipeComponentItemUnitTypeLabel | number) {
    if (typeof val === "number") {
      this.unitType = RecipeComponentItemUnitType.IngredientUnit;
      this.ingredientUnitId = val;
    } else {
      this.unitType = RecipeComponentItemUnitTypeDictReverse[val];
    }
  }
  public get unitTypeModel(): RecipeComponentItemUnitTypeLabel | number {
    return this.unitType === RecipeComponentItemUnitType.IngredientUnit
      ? this.ingredientUnitId!
      : RecipeComponentItemUnitTypeDict[this.unitType] as RecipeComponentItemUnitTypeLabel;
  }

  public setIngredientUnit(unit: IngredientUnitEntity): void {
    this.ingredientUnit = unit;
  }
}

export const ValidatorRules = {
  propItem: [requiredRule],
  amount: [ requiredRule, typeNumberRule, createDecimalRule(11,6), createMinRule(0.000001)],
};
